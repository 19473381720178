<template>
<center style="margin-top: -10px">
    <div class="bxslider">
        <div v-for="(sabor, index) in sabores" :key="index">
            <img v-if="sabor.hasImagem" :src="'./img/sabores/'+sabor.img[0]" :title="sabor.nome" height="500" width="700">
        </div>
    </div>
</center>
</template>

<script>
export default {
    data() {
        return {
            sabores: require("@/scripts/sabores.js"),
        };
    },
    mounted() {
        // eslint-disable-next-line
        $('.bxslider').bxSlider({
            auto: true,
            autoControls: true,
            speed: 1000,
            stopAutoOnClick: true,
            pager: true,
            slideWidth: 700,
            infiniteLoop: true,
            captions: true,
            randomStart: true,
            controls: true,
        });
        // eslint-disable-next-line
        $('.bx-viewport').css('border-radius', '5px');
    }
};
</script>
