const sabores = [
    {
        id: 's1',
        nome: 'Morango com Suspiro',
        img: [
            'morango_com_suspiro1.jpg',
            'morango_com_suspiro2.jpg',
            'morango_com_suspiro3.jpg',
        ],
        hasImagem: true,
        grupo: 'morangocomsuspiro'
    },
    {
        id: 's2',
        nome: 'Morango com Brigadeiro Branco',
        img: [
            'morango_com_brigadeiro_branco.jpg',
        ],
        hasImagem: true,
        grupo: 'morangobrigadeirobranco'
    },
    {
        id: 's3',
        nome: 'Morango com Chocolate Preto - (Sensação)',
        img: [
            'sensacao.jpg',
            'sensacao_cobertura_marshmallow.jpg'
        ],
        hasImagem: true,
        grupo: 'sensacao'
    },
    {
        id: 's4',
        nome: 'Beijinho de Morango (com Côco)',
        img: [
            'beijinho_de_morango1.jpg',
            'beijinho_de_morango2.jpg'
        ],
        hasImagem: true,
        grupo: 'beijinhomorango'
    },
    {
        id: 's5',
        nome: 'Dois Amores com Morango',
        img: [
            '2_amores_morango.jpg',
        ],
        hasImagem: true,
        grupo: 'doisamoresmorango'
    },
    {
        id: 's6',
        nome: 'Ferrero Rocher',
        img: [
            'ferrero_rocher1.jpg',
            'ferrero_rocher2.jpg', 
            'ferrero_rocher3.jpg',
            'ferrero_rocher4.jpg',
        ],
        hasImagem: true,
        grupo: 'ferreiro'
    },
    {
        id: 's7',
        nome: 'Sonho de Valsa',
        img: [
            'sonho_valsa1.jpg',
            'sonho_valsa2.jpg',
            'sonho_valsa3.jpg',
        ],
        hasImagem: true,
        grupo: 'sonhovalsa'
    },
    {
        id: 's8',
        nome: 'Ouro Branco',
        img: [
            'ouro_branco.jpg',
        ],
        hasImagem: true,
        grupo: 'ourobranco'
    },
    {
        id: 's9',
        nome: 'Alpino',
        img: [
            'alpino.jpg',
        ],
        hasImagem: true,
        grupo: 'alpino'
    },
    {
        id: 's10',
        nome: 'Nozes',
        img: [
            'nozes.jpg',
        ],
        hasImagem: true,
        grupo: 'nozes'
    },
    {
        id: 's11',
        nome: 'Marta Rocha',
        img: [
            'marta_rocha1.jpg',
            'marta_rocha2.jpg',
        ],
        hasImagem: true,
        grupo: 'martarocha'
    },
    {
        id: 's12',
        nome: 'Cassata (Creme de Côco)',
        img: [
            'cassata_coco1.jpg',
            'cassata_coco2.jpg',
        ],
        hasImagem: true,
        grupo: 'cassata'
    },
    {
        id: 's13',
        nome: 'Floresta Negra',
        img: [
            'floresta_negra.jpg',
        ],
        hasImagem: true,
        grupo: 'florestanegra'
    },
    {
        id: 's14',
        nome: 'Prestígio',
        img: [
            'prestigio.jpg',
        ],
        hasImagem: true,
        grupo: 'prestigio'
    },
    {
        id: 's15',
        nome: 'Brigadeiro',
        img: [
            'brigadeiro.jpg',
            'brigadeiro2.jpg',
        ],
        hasImagem: true,
        grupo: 'brigadeiro'
    },
    {
        id: 's16',
        nome: 'Dois Amores',
        img: [
            '2_amores1.jpg',
            '2_amores2.jpg',
        ],
        hasImagem: true,
        grupo: 'doisamores'
    },
    {
        id: 's17',
        nome: 'Frutas Mistas',
        img: [
            'frutas_mistas.jpg',
            'frutas_mistas_brigadeiro_branco.jpg',
        ],
        hasImagem: true,
        grupo: 'frutasmistas'
    },
    {
        id: 's18',
        nome: 'Frutas Vermelhas',
        img: [
            'frutas_vermelhas.jpg',
        ],
        hasImagem: true,
        grupo: 'frutasvermelhas'
    },
    {
        id: 's19',
        nome: 'Quatro Leites',
        img: [
            'quatro_leites.jpg',
            'quatro_leites2.jpg',
            'quatro_leites3.jpg',
            'quatro_leites4.jpg',
        ],
        hasImagem: true,
        grupo: 'quatroleites'
    },
    {
        id: 's20',
        nome: 'Abacaxi com Creme de Castanha',
        img: [
            'abacaxi_creme_castanhas.jpg',
        ],
        hasImagem: false,
        grupo: 'abacaxicremecastanha'
    },
    {
        id: 's21',
        nome: 'Ameixa com Côco',
        img: [
            'ameixa_coco.jpg',
        ],
        hasImagem: true,
        grupo: 'ameixacoco'
    },
    {
        id: 's22',
        nome: 'Damasco com Brigadeiro Branco',
        img: [
            'damasco_brigadeiro_branco.jpg',
            'damasco_brigadeiro_branco1.jpg',
            'damasco_brigadeiro_branco2.jpg',
        ],
        hasImagem: true,
        grupo: 'damascobrigadeiro'
    },
    {
        id: 's23',
        nome: 'Damasco com Creme de Castanha',
        img: [
            'damasco_creme_castanha.jpg',
            'damasco_creme_castanha1.jpg',
        ],
        hasImagem: true,
        grupo: 'damascocastanha'
    },
    {
        id: 's24',
        nome: 'Café',
        img: [
            'cafe.jpg',
        ],
        hasImagem: true,
        grupo: 'cafe'
    },
];

module.exports = sabores;