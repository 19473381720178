import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';

const beforeEnterFunc = (to, from, next) => {
    let titleName = "Home"
    if (to.name !== 'default' && to.name !== 'all')  {
        titleName = to.name.replace(/\b\w/g, l => l.toUpperCase())
    }
    document.title = `Dirce Bolos - ${titleName}`

    next()
}

Vue.use(Router);



export default new Router({
    routes: [
        {
            path: '/',
            name: 'default',
            component: Home,
            beforeEnter: beforeEnterFunc
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            beforeEnter: beforeEnterFunc
        },
        {
            path: '/sabores',
            name: 'sabores',
            component: () => import('@/views/Sabores.vue'),
            beforeEnter: beforeEnterFunc
        },
        {
            path: '/galeria',
            name: 'galeria',
            component: () => import('@/views/Galeria.vue'),
            beforeEnter: beforeEnterFunc
        },
        {
            path: '/perguntas',
            name: 'perguntas',
            component: () => import('@/views/Perguntas.vue'),
            beforeEnter: beforeEnterFunc
        },
        {
            path: '/contato',
            name: 'contato',
            component: () => import('@/views/Contato.vue'),
            beforeEnter: beforeEnterFunc
        }, 
        {
            path: '*',
            name: 'all',
            component: () => import('@/views/Home.vue'),
            beforeEnter: beforeEnterFunc
        }, 
    ],
    mode: 'history',
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});
