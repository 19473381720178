<template>
    <div>
        <PageHeader titleHeader="Bem-Vindo (a)" />
        <HomeSlider />
        <div class="row panel-home">
            <div class="col-md-12 body-on">
                <br>
                <h3 class="font-weight-bold">Um pouco sobre tudo</h3>
                <span class="lead">
                    <strong>Experiência:</strong> Trabalho com confeitaria
                    <u>há mais de 20 anos</u>. No começo trabalhava com salgados, doces e bolos. Contudo, decidi me aprofundar somente
                    na confecção de bolos. Fiz vários cursos e segui criando sabores com coberturas personalizadas.
                    <br>
                    <br>
                    <strong>Produtos:</strong> Utilizo os melhores produtos do mercado para confecção dos bolos.
                    <br>
                    <br>
                    <strong >Encomendas:</strong> Em datas comemorativas, festivas e feriados é sempre bom fazer a encomenda com bastante
                    antecedência, pois os pedidos são muitos nessas épocas.
                    <br>
                    <br>
                    <strong>Sabores:</strong> São diversos sabores de bolos e diversas coberturas diferenciadas.
                    <span style="cursor:pointer" class='underlineSection font-weight-bold' @click="$router.push({name:'sabores'})"><span><a>Entre na seção de sabores para mais detalhes.</a></span></span>
                    <br>
                    <br>
                    <ul class="underlineSection">
                        
                    </ul> 
                    <strong>Tamanhos:</strong> A grande maioria das dúvidas é, geralmente, qual tamanho de bolo encomendar. Isso varia muito de
                    acordo com o número de pessoas que irão comparecer no evento, se será servido docinhos, salgadinhos ou outros
                    petiscos. Segue um quadro resumido de proporção de tamanho por quantidade de fatias:
                    <div class='panel-home-tamanhos'>
                        <ul class='panel-home-tamanhos-itens'>
                            <li>
                                <u>Mini:</u>
                                Serve em torno de 12 a 14 fatias.</li>
                            <li>
                                <u>Pequeno:</u>
                                Serve em torno de 26 a 28 fatias.</li>
                            <li>
                                <u>Médio:</u>
                                Serve em torno de 46 a 48 fatias.</li>
                            <li>
                                <u>Tamanhos Grandes:</u>
                                Existem vários tamanhos grandes a partir de 66 fatias.</li>
                        </ul>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import HomeSlider from '@/components/HomeSlider.vue'
export default {
  name: "Home",
  components: {
    PageHeader,
    HomeSlider
  }
};
</script>

<style scoped>
.underlineSection {
  list-style-type: none;
  padding-left: 0;
}
.underlineSection span {
  cursor: pointer;
}
.underlineSection span,
.underlineSection span:after,
.underlineSection span:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.underlineSection span > a {
  text-decoration: none;
  z-index: 1;
  margin: 0;
  padding: 0;
  border: 0 none;
  position: relative;
  outline: none;
}

.underlineSection span > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: #124b84;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.5s linear;
}
.underlineSection span > a:hover:before,
.underlineSection span > a:focus:before {
  visibility: visible;
  transform: scaleX(1);
}

</style>
