<template>
    <div class="page-header" style="text-align: center">
        <h1>
            <strong>{{computedTitle}}</strong>
        </h1>
        <br>
        <p class="lead">{{computedSubtitle}}</p>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    props: ['titleHeader', 'subtitleHeader'],
    computed: {
        computedTitle() {
            return this.titleHeader 
        },
         computedSubtitle() {
            return this.subtitleHeader 
        }
    }
}
</script>
