<template>
    <div class="row menu-bar">
        <div class="col-md-1 menu-off"></div>       
        <router-link tag="div" class="col-md-2 menu-on" :to="{name:'home'}">Home</router-link>
        <router-link tag="div" class="col-md-2 menu-on" :to="{name:'sabores'}">Sabores</router-link>
        <router-link tag="div" class="col-md-2 menu-on" :to="{name:'galeria'}">Galeria</router-link>
        <router-link tag="div" class="col-md-2 menu-on" :to="{name:'perguntas'}">Perguntas</router-link>
        <router-link tag="div" class="col-md-2 menu-on" :to="{name:'contato'}">Contato</router-link>
        <div class="col-md-1 menu-off"></div>
    </div>
</template>

<script>
export default {
    name: 'Header'
};
</script>