<template>
    <div id="app">
        <Header />
        <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
            <router-view></router-view>
        </transition>            
    </div>
</template>

<script>
import Header from '@/layout/Header.vue'
export default {
  name: "App",
  components: {
      Header
  }
};
</script>


